<template>
  <modal
    :name="modalName"
    adaptive
    :width="500"
    @before-open="beforeOpen"
    :clickToClose="false"
    height="auto"
    class="check--booking-modal"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.header') }}
      </div>
      <SectionLoader :show="loading" />
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">
          {{ $t('booking.header') }}
        </div>
        <div class="desc">
          {{ message }}
        </div>
        <div class="row justify-center align-center mt-4">
          <div class="col-12">
            <button class="btn btn-primary btn-block" @click="close">
              {{ $t('booking.exist.redirectBtn') }}
            </button>
          </div>
        </div>
      </section>
    </div>
  </modal>
</template>

<script>
const SectionLoader = () => import('@/components/content-loading/section-loading');
export default {
  name: 'cancel-booking',
  components: {
    SectionLoader,
  },
  data: () => ({
    modalName: 'modal--check-booking',
    message: '',
    uuid: '',
    loading: false,
  }),
  methods: {
    close() {
      this.$modal.hide(this.modalName);
      this.$router.push('/dashboard/mybooking');
    },
    beforeOpen(event) {
      this.message = event.params.message;
    },
  },
};
</script>

<style scoped></style>
